import { v4 as uuidv4 } from 'uuid';
import { startTimer } from '../../authProvider';

const chunkSize = 512000; // 0,5 MiB
let cancelled = false;

export const cancelUpload = () => {
  cancelled = true;
};

const uploadFile = async (file, uploadUrl, updateCb) => {
  const uuid = uuidv4();
  const reader = new FileReader();

  const uploadChunk = (chunkNumber, istLast) => {
    // Reset logout timer with every chunk to allow uplopad of large files.
    startTimer();
    const formData = new FormData();
    formData.append('file', file.slice(chunkNumber * chunkSize, (chunkNumber + 1) * chunkSize));

    return fetch(uploadUrl, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        uuid,
        last: istLast
      },
      body: formData
    });
  };

  const fileInfo = {
    name: file.name,
    size: file.size,
    uploadId: uuid,
  };

  let currentChunk = 0;
  const chunkCount = Math.ceil(fileInfo.size / chunkSize);
  updateCb(0);
  while (currentChunk < chunkCount) {
    if (cancelled) {
      cancelled = false;
      updateCb(0);
      throw new Error('Upload cancelled');
    }
    await uploadChunk(currentChunk, chunkCount === currentChunk + 1);
    currentChunk++;
    updateCb(100 / chunkCount * currentChunk);
  }

  return fileInfo;
};

export default uploadFile;
