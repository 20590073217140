
const API_URL = process.env.REACT_APP_API_URL;

const SESSION_PING = 5 * 60 * 1000; // pnig every 5min
let sessionPingTimeout = null;
let logoutTimeout = null;
const session = {
  isLoggedIn: false,
  user: null,
  permissions: null,
  expiresAt: null
};

function loadAuth() {
  return fetch(`${API_URL}/load_auth`, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    },
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error('Invalid response');
    })
    .then(data => {
      if (data.user) {
        session.user = data.user;
        session.permissions = data.perms;
        session.expiresAt = new Date(data.sessionExpires);
        session.isLoggedIn = true;

        // if (!sessionPingTimeout) {
        //   window.clearTimeout(sessionPingTimeout);
        //   sessionPingTimeout = window.setTimeout(loadAuth, SESSION_PING);
        // }
        monitorLogoutTrigger();
        return Promise.resolve(data.user);
      }
      return Promise.reject(new Error('Access Denied. If you\'ve been inactive for longer time, login again.'));
    });
}
export const startTimer = () => {
  if (logoutTimeout) clearTimeout(logoutTimeout);
  logoutTimeout = setTimeout(() => {
    // TODO: It would be nicer to just redirect without and reload.
    // That would allow us to add an error message "You have been logged out due to inactivity."
    authProvider.logout().then(() => { window.location.href = '/login'; })
  }, 300000); // 5 min
}

const monitorLogoutTrigger = () => {
  startTimer();

  window.addEventListener('click', startTimer);
  window.addEventListener('mousemove', startTimer);
}

const authProvider = {
  login: params => Promise.resolve(),
  logout: () => new Promise(resolve => {
    window.removeEventListener('click', startTimer);
    window.removeEventListener('mousemove', startTimer);
    fetch(`${API_URL}/logout`, { credentials: 'include' })
      .then(() => {
        session.isLoggedIn = false;
        session.user = null;
        session.permissions = null;

        if (sessionPingTimeout) {
          window.clearTimeout(sessionPingTimeout);
          sessionPingTimeout = null;
        }

        resolve();
      });
  }),
  checkAuth: params => loadAuth(),
  checkError: error => Promise.resolve(), // TODO: handle auth errors
  getPermissions: params => {
    if (session.permissions) {
      return Promise.resolve(session.permissions);
    }
    return loadAuth()
      .then(() => Promise.resolve(session.permissions));
  },
  getSession: () => session,
};

export default authProvider;

window.addEventListener('beforeunload', () => {
  authProvider.logout();
});
